import { TEAM_LOGOS } from '../gallery/Gallery';
import { TEAMS } from '../globalConstants/Teams';

export interface TOP_SCORER {
  name: string;
  team: string;
  goals: number;
}

export interface STANDING {
  name: string;
  played: number;
  goals: number;
  points: number;
  logo: any;
}

//Top scorers list
export const TOP_SCORERS: TOP_SCORER[] = [
  {
    name: 'Miki Tadesse',
    team: TEAMS.AYDER_MINNESOTA,
    goals: 6,
  },
  {
    name: 'Thomas Mena',
    team: TEAMS.AYDER_MINNESOTA,
    goals: 4
  },
  {
    name: 'Miki Daniel',
    team: TEAMS.AYDER_MINNESOTA,
    goals: 3
  },
  {
    name: 'Yonas Habte',
    team: TEAMS.REMETS_SEATTLE,
    goals: 3,
  },
  {
    name: 'Yacob',
    team: TEAMS.REMETS_SEATTLE,
    goals: 3
  },
  {
    name: 'Robel Tsegay',
    team: TEAMS.TDF_DMV,
    goals: 6
  },
  {
    name: 'Biniam Gebregiorgis',
    team: TEAMS.TDF_DMV,
    goals: 4
  },
];

//Team standings
export const STANDINGS: STANDING[][] = [
  [
    { name: TEAMS.AYDER_MINNESOTA, played: 3, goals: 9, points: 9, logo: TEAM_LOGOS.AYDER },
    { name: TEAMS.REMETS_SEATTLE, played: 3, goals: 4, points: 6, logo: TEAM_LOGOS.REMETS },
    { name: TEAMS.HAYELOM_SAN_DIEGO, played: 3, goals: -4, points: 1, logo: TEAM_LOGOS.HAYELOM },
    { name: TEAMS.SEGAR_HOUSTON, played: 3, goals: -9, points: 1, logo: TEAM_LOGOS.SEGAR },
  ],
  [
    { name: TEAMS.BELES_TORONTO, played: 3, goals: 6, points: 7, logo: TEAM_LOGOS.BELES },
    { name: TEAMS.AYDER_2_MINNESOTA, played: 3, goals: -9, points: 0, logo: TEAM_LOGOS.UNKNOWN },
    { name: TEAMS.MONALIZA_DALLAS, played: 3, goals: 1, points: 4, logo: TEAM_LOGOS.MONLIZA },
    { name: TEAMS.TDF_DMV, played: 3, goals: 2, points: 6, logo: TEAM_LOGOS.TDF },
  ],
];
