export const DALLAS_TEAMS = ['Dallas Texas', 'San Diego', 'DMV', 'Portland'];
export const DENVER_TEAMS = ['Denver', 'Denver', 'Boston'];

export enum TEAMS {
  TDF_DMV = 'TDF DMV',
  REMETS_SEATTLE = 'Remetse Seattle',
  AYDER_MINNESOTA = 'Ayder Minnesota',
  HAYELOM_SAN_DIEGO = 'Hayelom San',
  SEGAR_HOUSTON = 'Segar Houston',
  BELES_TORONTO = 'Beles Toronto',
  MONALIZA_DALLAS = 'Monaliza Dalls',
  AYDER_2_MINNESOTA = 'Minnesota-2',
}
