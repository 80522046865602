import { Box, Stack, Typography, alpha } from '@mui/material';
import { Theme } from '../../globalConstants/Theme';
import { SocialMedias } from '../footer/FooterSocialMedia';
import { Player } from '@lottiefiles/react-lottie-player';

const ContactUsContainer = () => {
  return (
    <Box
      sx={{
        width: 'auto',
        minHeight: window.innerHeight - 296,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Stack spacing={10} sx={{ width: window.innerWidth, maxWidth: 550, height: 200 }}>
        <Player src={require('../../gallery/lottieFiles/contact-us.json')} loop autoplay style={{ width: 200, height: 200 }} />
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 500,
            color: alpha(Theme.black, 0.5),
            fontSize: { xs: 14, md: 16, lg: 18, xl: 20 },
            p: 1,
          }}
        >
          <b>Note:</b> for a faster response please reach us out through one of our social medias.
        </Typography>
        <SocialMedias />
      </Stack>
    </Box>
  );
};

export default ContactUsContainer;
