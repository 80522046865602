import { Box, Link, Stack, Typography, alpha } from '@mui/material';
import React from 'react';
import { Theme } from '../../globalConstants/Theme';
import { useNavigate } from 'react-router-dom';

interface StackContainerProps {
  children: React.ReactNode;
}

const FooterDetails = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: Theme.footerBg,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <StackContainer>
        <Box
          component="img"
          src={require('../../gallery/tsfna.png')}
          sx={{ width: 300, height: 200, objectFit: 'fit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
        <Box sx={{ width: 350 }}>
          <Typography style={{ color: '#D8D8D8', fontSize: 16, fontWeight: '500' }}>
            Tigrayan Soccer Federation In North America (TSFNA), is a non-profit organization that operates as a Amateur Soccer League for
            all age group.
          </Typography>
        </Box>
      </StackContainer>
      <Stack direction={{ xs: 'row', md: 'row', lg: 'row', xl: 'row' }} sx={{ width: '100%' }}>
        <StackContainer>
          <Typography style={{ color: '#D8D8D8', fontSize: 20, fontWeight: 'bold' }}>Get Started</Typography>
          <Link
            component="button"
            onClick={() => navigate('/')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            Home
          </Link>
          <Link
            component="button"
            onClick={() => navigate('/gallery')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            Gallery
          </Link>
          <Link
            component="button"
            onClick={() => navigate('/about')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            About
          </Link>
          <Link
            component="button"
            onClick={() => navigate('/about')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            Executive Committee
          </Link>
          <Link
            component="button"
            onClick={() => navigate('/contact-us')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            Contact US
          </Link>
        </StackContainer>
        <StackContainer>
          <Typography style={{ color: '#D8D8D8', fontSize: 20, fontWeight: 'bold' }}>Tournament</Typography>
          <Link
            component="button"
            onClick={() => navigate('/tournament')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            {new Date().getFullYear()} Tournamnet
          </Link>

          <Link
            component="button"
            onClick={() => navigate('/cup-winners')}
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
          >
            Cup Winners
          </Link>
          <Link
            component="button"
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
            onClick={() => navigate('/teams')}
          >
            Teams
          </Link>
          <Link
            component="button"
            underline="none"
            sx={{
              color: '#D8D8D8',
              ':hover': {
                color: alpha(Theme.orange, 0.8),
              },
            }}
            onClick={() => navigate('/register')}
          >
            Team Registration
          </Link>
        </StackContainer>
      </Stack>
    </Box>
  );
};

export default FooterDetails;

const StackContainer = ({ children }: StackContainerProps) => {
  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
        height: 350,
        backgroundColor: Theme.mainBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        mt: 3,
      }}
    >
      {children}
    </Stack>
  );
};
