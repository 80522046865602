import React from 'react';
import { motion } from 'framer-motion';
import ImageCard from './ImageCard';

interface CardProps {
  src: string;
  title: string;
  description: string;
  index: number;
}
export const AnimatedCard = ({ src, title, description, index }: CardProps) => {
  return (
    <motion.div
      className="card"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: window.innerWidth < 700 ? 0 : index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: false }}
    >
      <ImageCard src={src} title={title} descriptions={description} />
    </motion.div>
  );
};
