import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutContainer from './components/about/AboutContainer';
import ContactUsContainer from './components/contact-us/ContactUsContainer';
import CupWinnersContainer from './components/cup-winners/CupWinnersContainer';
import GalleryContainer from './components/gallery/GalleryContainer';
import Header from './components/header/Header';
import HomeContainer from './components/home/HomeContainer';
import RegisterContainer from './components/register/RegisterContainer';
import TeamsContainer from './components/teams/TeamsContainer';
import FooterContainer from './components/footer/FooterContainer';
import { ScrollToTop } from './components/common/ScrollToTop';
import TournamentContainer from './components/tournament/TournamentContainer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/tournament" element={<TournamentContainer />} />
          <Route path="/teams" element={<TeamsContainer />} />
          <Route path="/cup-winners" element={<CupWinnersContainer />} />
          <Route path="/gallery" element={<GalleryContainer />} />
          <Route path="/about" element={<AboutContainer />} />
          <Route path="/contact-us" element={<ContactUsContainer />} />
          <Route path="/register" element={<RegisterContainer />} />
        </Routes>
        <FooterContainer />
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
