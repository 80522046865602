import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

interface TeamCardPros {
  teamName: string;
  photo: any;
}
const TeamCard = ({ teamName, photo }: TeamCardPros) => {
  return (
    <Card sx={{ width: 400, m: 3 }}>
      <CardActionArea>
        <CardMedia component="img" height="300" image={photo} sx={{}} />
        <CardContent sx={{}}>
          <Typography gutterBottom fontFamily="monospace">
            {teamName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamCard;
