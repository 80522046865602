import { Stack } from '@mui/material';
import { VALUES_PHOTO } from '../../gallery/tsfna-values/TSFNA_VALUES';
import { AnimatedCard } from '../common/AnimatedCard';

interface VisionMissionContainerProps {
  photos: VALUES_PHOTO[];
}
const VisionMissionContainer = ({ photos }: VisionMissionContainerProps) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      spacing={3}
      sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}
    >
      {photos.map((item, index) => (
        <AnimatedCard index={index} title={item.title} src={item.photo} description={item.description} />
      ))}
    </Stack>
  );
};

export default VisionMissionContainer;
