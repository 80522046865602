import { STANDING, TOP_SCORER } from '../../gameStore/teamStandins';

export const sortByPointsAndGoals = (teamStandings: STANDING[]) => {
  teamStandings.sort(function (a, b) {
    if (b.points === a.points) {
      return b.goals - a.goals;
    }
    return b.points > a.points ? 1 : -1;
  });

  return teamStandings;
};

export const sortByGoals = (players: TOP_SCORER[]) => {
  if (players.length === 0) {
    return [
      {
        name: 'Player name',
        team: 'team',
        goals: 0,
      },
      {
        name: 'Player name',
        team: 'team',
        goals: 0,
      },
      {
        name: 'Player name',
        team: 'team',
        goals: 0,
      },
      {
        name: 'Player name',
        team: 'team',
        goals: 0,
      },
      {
        name: 'Player name',
        team: 'team',
        goals: 0,
      },
    ];
  }

  players.sort((a, b) => b.goals - a.goals);
  // Return only the top 5 elements
  return players;
};
