import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Theme } from '../../globalConstants/Theme';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  width: window.innerWidth - 10,
  maxWidth: 500,
  //   height: 600,
  boxShadow: 24,
};

interface ImageViewModalProps {
  image: any;
  openModal: boolean;
  onCancel: () => void;
}
interface CancelIconProps {
  onClick: () => void;
}
export default function ImageViewModal({ image, openModal, onCancel }: ImageViewModalProps) {
  return (
    <Modal open={openModal} onClose={() => {}}>
      <Box sx={style}>
        <CancelIcon onClick={onCancel} />
        <Box component="img" src={image} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </Box>
    </Modal>
  );
}

const CancelIcon = ({ onClick }: CancelIconProps) => {
  return (
    <Box sx={{ position: 'absolute' as 'absolute', top: -10, right: -10, pb: 10 }}>
      <IconButton onClick={onClick}>
        <HighlightOffIcon fontSize="large" sx={{ color: Theme.mainBg }} />
      </IconButton>
    </Box>
  );
};
