import { Box } from '@mui/material';
import React from 'react';
import FooterDetails from './FooterDetails';
import FooterSocialMedia from './FooterSocialMedia';

const FooterContainer = () => {
  return (
    <Box component="footer" sx={{ mt: 10 }}>
      <FooterDetails />
      <FooterSocialMedia />
    </Box>
  );
};

export default FooterContainer;
