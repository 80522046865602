import { MatchCardProps } from '../components/tournament/MatchCard';
import { TEAM_LOGOS } from '../gallery/Gallery';
import { TEAMS } from '../globalConstants/Teams';

export interface FIELD {
  fieldNumber: number;
  address: string;
}

//FIELDS
export const FIELDS: FIELD[] = [
  {
    fieldNumber: 1,
    address: '1234 Abc ave.',
  },
  {
    fieldNumber: 2,
    address: '1234 Def ave.',
  },
];

//MATCHE
export const MATCHES: MatchCardProps[][] = [
  [
    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '8:00am',
      },
      teamA: {
        name: TEAMS.AYDER_MINNESOTA,
        logo: TEAM_LOGOS.AYDER,
        goals: 3,
      },
      teamB: {
        name: TEAMS.REMETS_SEATTLE,
        logo: TEAM_LOGOS.REMETS,
        goals: 1,
      },
    },

    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '9:30am',
      },
      teamA: {
        name: TEAMS.HAYELOM_SAN_DIEGO,
        logo: TEAM_LOGOS.HAYELOM,
        goals: 0,
      },
      teamB: {
        name: TEAMS.SEGAR_HOUSTON,
        logo: TEAM_LOGOS.SEGAR,
        goals: 0,
      },
    },

    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '11:00am',
      },
      teamA: {
        name: TEAMS.BELES_TORONTO,
        logo: TEAM_LOGOS.BELES,
        goals: 5,
      },
      teamB: {
        name: TEAMS.TDF_DMV,
        logo: TEAM_LOGOS.TDF,
        goals: 2,
      },
    },

    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '5:00pm',
      },
      teamA: {
        name: TEAMS.MONALIZA_DALLAS,
        logo: TEAM_LOGOS.MONLIZA,
        goals: 3,
      },
      teamB: {
        name: TEAMS.AYDER_2_MINNESOTA,
        logo: TEAM_LOGOS.UNKNOWN,
        goals: 0,
      },
    },

    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '6:30pm',
      },
      teamA: {
        name: TEAMS.AYDER_MINNESOTA,
        logo: TEAM_LOGOS.AYDER,
        goals: 3,
      },
      teamB: {
        name: TEAMS.HAYELOM_SAN_DIEGO,
        logo: TEAM_LOGOS.HAYELOM,
        goals: 1,
      },
    },

    {
      matchTime: {
        date: 'Wed, Jun 26',
        time: '8:00pm',
      },
      teamA: {
        name: TEAMS.REMETS_SEATTLE,
        logo: TEAM_LOGOS.REMETS,
        goals: 4,
      },
      teamB: {
        name: TEAMS.SEGAR_HOUSTON,
        logo: TEAM_LOGOS.SEGAR,
        goals: 0,
      },
    },
  ],
  [
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '7:00am',
      },
      teamA: {
        name: TEAMS.BELES_TORONTO,
        logo: TEAM_LOGOS.BELES,
        goals: 0,
      },
      teamB: {
        name: TEAMS.MONALIZA_DALLAS,
        logo: TEAM_LOGOS.MONLIZA,
        goals: 0,
      },
    },
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '8:30am',
      },
      teamA: {
        name: TEAMS.TDF_DMV,
        logo: TEAM_LOGOS.TDF,
        goals: 3,
      },
      teamB: {
        name: TEAMS.AYDER_2_MINNESOTA,
        logo: TEAM_LOGOS.UNKNOWN,
        goals: 0,
      },
    },
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '10:00am',
      },
      teamA: {
        name: TEAMS.AYDER_MINNESOTA,
        logo: TEAM_LOGOS.AYDER,
        goals: 6,
      },
      teamB: {
        name: TEAMS.SEGAR_HOUSTON,
        logo: TEAM_LOGOS.SEGAR,
        goals: 1,
      },
    },
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '1:30pm',
      },
      teamA: {
        name: TEAMS.REMETS_SEATTLE,
        logo: TEAM_LOGOS.REMETS,
        goals: 3,
      },
      teamB: {
        name: TEAMS.HAYELOM_SAN_DIEGO,
        logo: TEAM_LOGOS.HAYELOM,
        goals: 1,
      },
    },
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '3:00pm',
      },
      teamA: {
        name: TEAMS.BELES_TORONTO,
        logo: TEAM_LOGOS.BELES,
        goals: 3,
      },
      teamB: {
        name: TEAMS.AYDER_2_MINNESOTA,
        logo: TEAM_LOGOS.UNKNOWN,
        goals: 0,
      },
    },
    {
      matchTime: {
        date: 'Thu, Jun 27',
        time: '4:30pm',
      },
      teamA: {
        name: TEAMS.TDF_DMV,
        logo: TEAM_LOGOS.TDF,
        goals: 6,
      },
      teamB: {
        name: TEAMS.MONALIZA_DALLAS,
        logo: TEAM_LOGOS.MONLIZA,
        goals: 4,
      },
    },
  ],
  [
    {
      matchTime: {
        date: 'Fri, Jun 28',
        time: '4:00pm',
      },
      teamA: {
        name: TEAMS.AYDER_MINNESOTA,
        logo: TEAM_LOGOS.AYDER,
        goals: 4,
      },
      teamB: {
        name: TEAMS.TDF_DMV,
        logo: TEAM_LOGOS.TDF,
        goals: 1,
      },
    },

    {
      matchTime: {
        date: 'Fri, Jun 28',
        time: '6:00pm',
      },
      teamA: {
        name: TEAMS.BELES_TORONTO,
        logo: TEAM_LOGOS.BELES,
        goals: 1
      },
      teamB: {
        name: TEAMS.REMETS_SEATTLE,
        logo: TEAM_LOGOS.REMETS,
        goals: 2
      },
    },
  ],
  [
    {
      matchTime: {
        date: 'Sat, Jun 29',
        time: '2:00pm',
      },
      teamA: {
         name: TEAMS.TDF_DMV,
        logo: TEAM_LOGOS.TDF,
        goals: 3,
      },
      teamB: {
        name: 'TBD',
        logo: TEAM_LOGOS.UNKNOWN,
        goals: 2,
      },
    },
  ],
  [
    {
      matchTime: {
        date: 'Sat, Jun 29',
        time: '4:00pm',
      },
      teamA: {
         name: TEAMS.AYDER_MINNESOTA,
        logo: TEAM_LOGOS.AYDER,
        goals: undefined,
      },
      teamB: {
        name: TEAMS.REMETS_SEATTLE,
        logo: TEAM_LOGOS.REMETS,
        goals: undefined,
      },
    },
  ],
];
