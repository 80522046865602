import { Stack } from '@mui/material';
import { CSSProperties } from 'react';
import 'react-awesome-slider/dist/styles.css';
import { PHOTO } from '../../gallery/Gallery';
import './carousel.css'; // Import your custom CSS

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

interface ImageCarouselProps {
  photos: PHOTO[];
}

const indicatorStyles: CSSProperties = {
  background: '#FFCCCB',
  width: 8,
  height: 8,
  borderRadius: 8,
  display: 'inline-block',
  margin: '0 8px',
};

export const CarouselComponent = ({ photos }: ImageCarouselProps) => {
  const { innerWidth: width } = window;
  return (
    <Stack mt={10} direction="row" width={width > 700 ? width * 0.8 : width - 10}>
      <Carousel
        width="100%"
        autoPlay
        infiniteLoop
        showThumbs={false}
        // centerMode
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: '#FFA500' }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {photos.map(photo => (
          <div key={photo.id}>
            <img src={photo.photo} alt={photo.description} height={width > 700 ? 500 : 300} />
            {/* <p className="legend">{photo.description}</p> */}
          </div>
        ))}
      </Carousel>
    </Stack>
  );
};
