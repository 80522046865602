import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TOP_SCORER } from '../../gameStore/teamStandins';
import { sortByGoals } from '../utils/utils';
import { TEAM_LOGOS } from '../../gallery/Gallery';

interface TopScorersPorps {
  title: string;
  players: TOP_SCORER[];
}
export default function TopScorers({ title, players }: TopScorersPorps) {
  const getMadel = (place: number) => {
    place = place + 1;

    switch (place) {
      case 1:
        return require('../../gallery/madels/1st.webp');
      case 2:
        return require('../../gallery/madels/2nd.webp');
      case 3:
        return require('../../gallery/madels/3rd.webp');
      // case 4:
      //   return require('../../gallery/madels/4th.jpg');
      // case 5:
      //   return require('../../gallery/madels/5th.jpg');
      default:
        return TEAM_LOGOS.UNKNOWN;
    }
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ width: 380, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Table sx={{ width: '100%' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={4}
              sx={{
                borderBottom: 'none',
                fontSize: 18,
                fontWeight: '700',
              }}
            >
              {title}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell align="center">Goals</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortByGoals(players).map((player: TOP_SCORER, index) => (
            <TableRow key={player.name}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  wordBreak: 'break-word',
                }}
              >
                <Stack direction="row" spacing={3}>
                  <Box component="img" src={getMadel(index)} sx={{ width: 30, height: 30, borderRadius: 30, objectFit: 'contain' }} />
                  <Stack spacing={0}>
                    <Typography color="black" fontSize="16px" fontWeight={600}>
                      {player.name}
                    </Typography>
                    <Typography color="#808080" fontSize="12px">
                      {player.team}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell align="center">{player.goals}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
