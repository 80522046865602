import { Box, Stack } from '@mui/material';
import CupWinnerHeader from './CupWinnersHeader';

import TeamCard from '../teams/TeamCard';
import { CUP_WINNERS } from '../../gallery/Gallery';

const CupWinnersContainer = () => {
  return (
    <Box
      sx={{
        width: window.innerWidth,
        minHeight: window.innerHeight - 296,
        marginTop: 8.1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CupWinnerHeader />
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          width: window.innerWidth,
          maxWidth: 900,
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {CUP_WINNERS.map(team => (
          <TeamCard key={team.id} teamName={team.teamName} photo={team.photo} />
        ))}
      </Stack>
    </Box>
  );
};

export default CupWinnersContainer;
