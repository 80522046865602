import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useState } from 'react';
import GalleryList from './GallaryList';

import { PHOTO_2022 } from '../../gallery/Gallery';
import ImageViewModal from './ImageViewModal';

const GalleryContainer = () => {
  const [image, setImage] = useState<string>('');
  return (
    <Stack
      sx={{
        width: '100%',
        minHeight: window.innerHeight - 296,
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-star',
      }}
    >
      <ImageViewModal image={image} openModal={image.trim().length > 0} onCancel={() => setImage('')} />
      <Collapsible title="DMV 2022">
        <GalleryList photos={PHOTO_2022} executives={false} setImage={setImage} />
      </Collapsible>
      <Collapsible title="Dallas 2023">
        <GalleryList photos={undefined} executives={false} setImage={setImage} />
      </Collapsible>
    </Stack>
  );
};

export default GalleryContainer;

interface CollapisbleProps {
  title: string;
  children: React.ReactNode;
}
const Collapsible = ({ title, children }: CollapisbleProps) => {
  return (
    <Accordion sx={{ width: window.innerWidth - 10, maxWidth: 1000 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
