import { Box, Typography, alpha } from '@mui/material';
import { Theme } from '../../globalConstants/Theme';
import GalleryList from '../gallery/GallaryList';
import About from './About';

const AboutContainer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: window.innerHeight - 296,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: window.innerWidth > 500 ? 10 : 2,
      }}
    >
      <Typography
        sx={{
          mr: 2,
          mt: 10,
          display: 'flex',
          fontFamily: 'monospace',
          fontWeight: 'bold',
          color: alpha(Theme.mainBg, 0.7),
          textDecoration: 'none',
          fontSize: { xs: 20, md: 20, lg: 28, xl: 30 },
        }}
      >
        Meet Our Executive Commitees
      </Typography>
      <GalleryList executives={true} setImage={() => {}} />
      <Typography
        sx={{
          mt: 10,
          display: 'flex',
          fontFamily: 'monospace',
          fontWeight: 'bold',
          color: alpha(Theme.mainBg, 0.9),
          textDecoration: 'none',
          fontSize: { xs: 20, md: 20, lg: 28, xl: 30 },
        }}
      >
        ABOUT TSFNA
      </Typography>
      <About hideTitle={true} />
    </Box>
  );
};

export default AboutContainer;
