export interface VALUES_PHOTO {
  id: number;
  photo: any;
  title: string;
  description: string;
}
export const TSFNA_VALUES: VALUES_PHOTO[] = [
  {
    id: 0,
    photo: require('./WhoWeAre.png'),
    title: 'Who We Are',
    description:
      'Tigrayan Soccer Federation In North America (TSFNA), is a non-profit organization that operates as a Amateur Soccer League for all age group.',
  },
  {
    id: 0,
    photo: require('./vision.png'),
    title: 'Our Vision',
    description: 'To build a strong and supportive Tigrayan athletics(soccer) community in North America.',
  },
  {
    id: 0,
    photo: require('./mission.png'),
    title: 'Our Mission',
    description:
      'To develop and promote Tigrayan culture through soccer, and foster unity and pride within the Tigrayan community across North America.',
  },
];

export const ABOUT_TSFNA =
  'A non-political, non-religious, and nonprofit organization,' +
  'the Tigrayan Soccer Federation in North America was established in 2023 ' +
  'with the goals of fostering a positive environment within the Tigrayan communities' +
  ' in North America and promoting Soccer and Tigrayan culture and traditions.' +
  'Its goal is to connect Tigrayans through networking opportunities and youth empowerment programs, ' +
  'particularly through soccer competitions, other sporting events, and cultural gatherings.';
