import { Box } from '@mui/material';

const CupWinnerHeader = () => {
  return (
    <Box
      component="img"
      src={require('../../gallery/cup_winners_bg.png')}
      sx={{
        width: {
          xs: window.innerWidth,
          sm: window.innerWidth,
          md: window.innerWidth,
          lg: window.innerWidth,
          xl: window.innerWidth / 2 - 100,
        },
        height: {
          xs: 300,
          sm: 300,
          md: 300,
          lg: 400,
          xl: 450,
        },
        backgroundColor: 'transparent',
      }}
    />
  );
};

export default CupWinnerHeader;
