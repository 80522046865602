import { Stack, Typography } from '@mui/material';
import MatchCard, { MatchCardProps } from './MatchCard';

export interface TEAM {
  name: string;
  logo: any;
  goals?: number;
}
interface MatchCardContainerProps {
  text: string;
  matches: MatchCardProps[];
}
const MatchCardContainer = ({ text, matches }: MatchCardContainerProps) => {
  return (
    <Stack
      spacing={1}
      sx={{
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 5,
      }}
    >
      <TextContainer text={text} />
      {matches.map((matche, index) => (
        <MatchCard key={index} teamA={matche.teamA} teamB={matche.teamB} matchTime={matche.matchTime} />
      ))}
    </Stack>
  );
};

export default MatchCardContainer;

interface TextContainerProps {
  text: string;
}
export const TextContainer = ({ text }: TextContainerProps) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignSelf: 'flex-start',
        paddingLeft: 3,
        fontSize: { xs: 16, md: 18, lg: 16, xl: 18 },
        fontWeight: 700,
        color: '#1a1a1a',
        textDecoration: 'none',
      }}
    >
      {text}
    </Typography>
  );
};
