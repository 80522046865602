import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import MatchCardContainer from './MatchCardContainer';
import TournamentSchedule from './TournamentSchedule';
import { MATCHES } from '../../gameStore/Matches';
import TeamStanding from './TeamStanding';
import { STANDINGS, TOP_SCORERS } from '../../gameStore/teamStandins';
import TopScorers from './TopScorers';

const TournamentContainer = () => {
  const [selectedTab, setSelectedTab] = useState('matches');
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: window.innerHeight - 296,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
      }}
    >
      <TournamentSchedule selected={selectedTab} setSelected={setSelectedTab} />

      {selectedTab === 'standings' ? (
        <Stack direction={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }} spacing={2} mt={5} mb={50}>
          <TeamStanding group="Group A" teamsStanding={STANDINGS[0]} />
          <TeamStanding group="Group B" teamsStanding={STANDINGS[1]} />
        </Stack>
      ) : selectedTab === 'stats' ? (
        <Stack direction={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }} spacing={2} mt={5} mb={50}>
          <TopScorers title="Top Scorers" players={TOP_SCORERS} />
        </Stack>
      ) : (
        <>
          <MatchCardContainer text="Group stage. Wed, Jun 26" matches={MATCHES[0]} />
          <MatchCardContainer text="Group stage. Thu, Jun 27" matches={MATCHES[1]} />
          <MatchCardContainer text="Semi-final. Fri, Jun 28" matches={MATCHES[2]} />
          <MatchCardContainer text="3rd Place. Sat, Jun 29" matches={MATCHES[3]} />
          <MatchCardContainer text="Final. Sat, Jun 29" matches={MATCHES[4]} />
        </>
      )}
    </Box>
  );
};

export default TournamentContainer;
