import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '../../globalConstants/Theme';
import { SOCILA_MEDIAS } from '../../globalConstants/links';
const FooterSocialMedia = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: Theme.footerBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        pb: 3,
      }}
    >
      <SocialMedias />
      <Typography style={{ color: '#D8D8D8', marginTop: 10, fontSize: 14 }}>Terms and Privarcy</Typography>

      <Typography style={{ color: '#D8D8D8', marginTop: 10, fontSize: 15 }}>
        © {new Date().getFullYear()} TSFNA. All rights reserved.
      </Typography>
    </Box>
  );
};
export default FooterSocialMedia;

export const SocialMedias = () => {
  return (
    <Stack spacing={2} direction="row" sx={{}} justifyContent="center" alignItems="center">
      <Box sx={{ width: 40, height: 40, backgroundColor: '#E1306C', borderRadius: 2 }}>
        <IconButton aria-label="Instagram" size="medium" sx={{ color: '#fff' }} onClick={() => window.open(SOCILA_MEDIAS.INSTAGRAM)}>
          <InstagramIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: 40, height: 40, backgroundColor: '#3b5998', borderRadius: 2 }}>
        <IconButton aria-label="Instagram" size="medium" sx={{ color: '#fff' }} onClick={() => window.open(SOCILA_MEDIAS.FACEBOOK)}>
          <FacebookIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: 40, height: 40, backgroundColor: '#FF0000', borderRadius: 2 }}>
        <IconButton aria-label="Youtube" size="medium" sx={{ color: '#fff' }} onClick={() => window.open(SOCILA_MEDIAS.YOUTUBE)}>
          <YouTubeIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: 60, height: 60, borderRadius: 2 }}>
        <IconButton aria-label="TikTok" size="medium" sx={{ color: '#fff' }} onClick={() => window.open(SOCILA_MEDIAS.TIKTOK)}>
          <Box
            component="img"
            src={require('../../gallery/icons/tiktok.png')}
            sx={{ width: '100%', height: '100%', objectFit: 'mainBg' }}
          />
        </IconButton>
      </Box>
    </Stack>
  );
};
