import { Box, Stack } from '@mui/material';
import { TEAMS } from '../../gallery/Gallery';
import TeamCard from './TeamCard';

const TeamsContainer = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          width: 900,
          height: 'auto',
          minHeight: window.innerHeight - 296,
          marginTop: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {TEAMS.map(team => (
          <TeamCard key={team.id} teamName={team.teamName} photo={team.photo} />
        ))}
      </Stack>
    </Box>
  );
};

export default TeamsContainer;
