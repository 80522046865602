import React from 'react';
import { motion } from 'framer-motion';
import { alpha } from '@mui/material';
import { Theme } from '../../globalConstants/Theme';

interface AnimatedTextProps {
  text: string;
  fontSize?: number;
  bg?: boolean;
}
const AnimatedText = ({ text, fontSize, bg = false }: AnimatedTextProps) => {
  // splitting text into letters
  const letters = Array.from(text);

  // Variants for Container
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
    }),
  };

  // Variants for each letter
  const child = {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: -20,
      y: 10,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      style={{
        overflow: 'hidden',
        display: 'block',
        fontSize: fontSize,
        fontWeight: 'bold',
        color: 'white',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      }}
      variants={container}
      initial="hidden"
      animate="visible"
      viewport={{ once: false }}
    >
      {letters.map((letter, index) => (
        <motion.span variants={child} key={index} style={{ backgroundColor: bg ? alpha(Theme.orange, 0.8) : 'undefined' }}>
          {letter === ' ' ? '\u00A0' : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default AnimatedText;
