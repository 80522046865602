import { HEADER_TITLES, TOURNAMENT_LIST } from './Enums';

export const LEFT_HEADER_TITLE = [];
export const RIGHT_HEADER_TITLE = [
  HEADER_TITLES.HOME,
  HEADER_TITLES.TOURNAMENT,
  HEADER_TITLES.GALLERY,
  HEADER_TITLES.ABOUT,
  HEADER_TITLES.CONTACT_US,
  HEADER_TITLES.REGISTER,
];

export const TOURNAMENT_CATEGORY = [TOURNAMENT_LIST.TOURNAMENT, TOURNAMENT_LIST.TEAMS, TOURNAMENT_LIST.CUP_WINNERS];
