import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { default as ExpandMore, default as ExpandMoreIcon } from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Collapse, Stack, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LEFT_HEADER_TITLE, RIGHT_HEADER_TITLE, TOURNAMENT_CATEGORY } from '../../globalConstants/Constants';
import { HEADER_TITLES, TOURNAMENT_LIST } from '../../globalConstants/Enums';
import { Theme } from '../../globalConstants/Theme';
import { AppBar, DrawerHeader, drawerWidth } from './HeaderStyles';
import HoverHeader from './HoverHeader';

export const HEADER_HEIGHT = 100;
const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [showTournament, setShowTournament] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const onClick = (page: string) => {
    if (page === HEADER_TITLES.TOURNAMENT) {
      // navigate('/tournament');
      // setHover(true);
      setShowTournament(!showTournament);
    } else if (page === HEADER_TITLES.REGISTER) {
      navigate('/register');
    } else if (page === HEADER_TITLES.GALLERY) {
      navigate('/gallery');
    } else if (page === HEADER_TITLES.CONTACT_US) {
      navigate('/contact-us');
    } else if (page === HEADER_TITLES.ABOUT) {
      navigate('/about');
    } else {
      navigate('/');
    }
    if (page !== HEADER_TITLES.TOURNAMENT) {
      setOpen(false);
    }
  };

  const onClickTournament = (item: string) => {
    if (item === TOURNAMENT_LIST.TOURNAMENT) {
      navigate('/tournament');
    } else if (item === TOURNAMENT_LIST.TEAMS) {
      navigate('/teams');
    } else if (item === TOURNAMENT_LIST.CUP_WINNERS) {
      navigate('/cup-winners');
    }
    setShowTournament(false);
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={0}
          open={open}
          style={{ width: '100%', height: HEADER_HEIGHT, backgroundColor: alpha(Theme.headerBg, 0.9) }}
        >
          <Toolbar sx={{ width: '100%', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  height: 68.5 - 17.125,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  color: '#D8D8D8',
                  border: 0,
                }}
              >
                <Box component="img" src={require('../../gallery/tsfna.png')} sx={{ width: 150, height: 150, objectFit: 'contain' }} />
              </Box>

              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {RIGHT_HEADER_TITLE.map((page, index) => (
                  <Box
                    key={index}
                    sx={{
                      height: HEADER_HEIGHT,
                      backgroundColor: 'transparent',
                      color: '#D8D8D8',
                      border: 0,
                      marginRight: 2,
                      ':hover': {
                        color: alpha(Theme.orange, 0.9),
                      },
                    }}
                    component="button"
                    onClick={() => onClick(page)}
                    onMouseOver={() => {
                      if (page === HEADER_TITLES.TOURNAMENT) {
                        setHover(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (page === HEADER_TITLES.TOURNAMENT) {
                        setHover(false);
                      }
                    }}
                  >
                    {page === 'Register' ? (
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          height: 30,
                          width: 150,
                          borderRadius: 20,
                          backgroundColor: Theme.orange,
                          textTransform: 'none',
                          fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '22px' },
                          ':hover': {
                            backgroundColor: Theme.orange,
                          },
                        }}
                        onClick={() => onClick(page)}
                      >
                        {page}
                      </Button>
                    ) : (
                      <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography
                          noWrap
                          sx={{
                            display: 'flex',
                            fontWeight: 700,
                            textTransform: 'none',
                            color: Theme.mainBg,
                            fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' },
                            ':hover': {
                              color: Theme.orange,
                            },
                          }}
                        >
                          {page}
                        </Typography>
                        {page === HEADER_TITLES.TOURNAMENT && <ExpandMoreIcon sx={{ color: Theme.mainBg }} />}
                      </Stack>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: 'none' }), color: '#4d4d4d' }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[...LEFT_HEADER_TITLE, ...RIGHT_HEADER_TITLE].map((text, index) => (
              <>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      onClick(text);
                    }}
                  >
                    <ListItemText primary={text} />
                    {text === HEADER_TITLES.TOURNAMENT ? showTournament ? <ExpandLess /> : <ExpandMore /> : null}
                  </ListItemButton>
                </ListItem>
                {text === HEADER_TITLES.TOURNAMENT && (
                  <Collapse in={showTournament} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {TOURNAMENT_CATEGORY.map((item, index) => (
                        <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => onClickTournament(item)}>
                          <ListItemText primary={item} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            ))}
          </List>
        </Drawer>
      </Box>

      <HoverHeader open={hover} setHover={setHover} />
    </Box>
  );
};

export default Header;
