import { Box, ListItem, Stack, Typography, alpha } from '@mui/material';
import { Theme } from '../../globalConstants/Theme';
import { SocialMedias } from '../footer/FooterSocialMedia';
import { Player } from '@lottiefiles/react-lottie-player';

const RegisterContainer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: window.innerHeight - 296,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Player src={require('../../gallery/lottieFiles/registration.json')} loop autoplay style={{ width: 200, height: 200 }} />
      <Typography
        sx={{
          display: 'flex',
          fontFamily: 'monospace',
          fontWeight: 'bold',
          color: alpha(Theme.mainBg, 0.9),
          fontSize: { xs: 18, md: 20, lg: 28, xl: 30 },
        }}
      >
        Team Registration
      </Typography>
      <Stack sx={{ width: window.innerWidth, maxWidth: 700, height: 200 }}>
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 500,
            color: alpha(Theme.black, 0.7),
            fontSize: { xs: 14, md: 16, lg: 18, xl: 20 },
            p: 1,
          }}
        >
          In order to better assist you please provide all the informations listed below.
        </Typography>
        <>
          <ListItem style={{ display: 'list-item' }}>Team representative and phone number</ListItem>
          <ListItem style={{ display: 'list-item' }}>Team name and state</ListItem>
        </>
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 500,
            color: alpha(Theme.black, 0.5),
            fontSize: { xs: 14, md: 16, lg: 18, xl: 20 },
            p: 1,
          }}
        >
          <b>Note</b>: for a faster response please reach us out through one of our social medias.
        </Typography>
        <SocialMedias />
      </Stack>
    </Box>
  );
};

export default RegisterContainer;
